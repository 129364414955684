import React from 'react'
import Layout from '../components/layouts/page'
import { Helmet } from 'react-helmet'

const About = () => {
	return (
		<div className="page">
    <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>About Devine Ride Limo Service in Southern California</title>
    </Helmet>
		<Layout>
			<section>
				<div className='py-5 bg-light'>
					<div className='container-xxl pt-5'>
						<div className='d-flex justify-content-center'>
							<div className='col-md-8'>
                <h1 className='display-1 fw-medium mb-4'>About Devine Ride</h1>
                <p className='fs-5 mb-4'>As a Southern California native, John remembers the glory days of being a young busboy at the San Clemente Hotel, which was a big deal at the time. It was just down the street from The Nixon (President Richard Nixon’s former “Western White House”, an oceanfront estate in San Clemente), where there was a continual list of important people coming and going. It gave him the opportunity to serve the likes of Henry Kissinger, which instilled his need to provide the very best level of service.</p>
                <p className='fs-5 mb-4'>John found it deeply fulfilling to interact with and help people from various walks of life. It makes sense that the service industry eventually became his calling – driving limos and transportation, to be specific, which is why he does so much of the driving himself. Of course, he has an amazing team, but he loves to be out there interacting directly with people and ensuring everyone is having a good time.</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='bg-light-white'>
					<div className='container-xxl'>
						<StaticImage 
							src='../images/southern-california-limo.jpg' 
							alt='A limo driving through downtown Los Angeles'
						/>
					</div>
				</div> */}
      </section>
    </Layout>
		</div>
  )
}

export default About